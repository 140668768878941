import { environment } from '../../environments/environment';

export const globalCompareId = (a: any, b: any) => (a && b) ? (a.id === b.id) : false;

export const globalCompareText = (a: any, b: any) => (a && b) ? (a.toString() === b.toString()) : false;

export const loadingImage = `${environment.staticUrl}assets/images/loading.svg`;

export const TinyMceConfig = {
    apiKey: 'hxedrq41lw7a69aahntckm7vp9j858mgz4qk3c1x7icebhzu',
    plugins: 'image media link table code',
    height: 300,
    skin: localStorage.getItem('theme') === 'dark' ? 'oxide-dark' : 'oxide',
    content_css: [
        localStorage.getItem('theme') == 'dark' ? 'dark' : '',
        'https://fonts.googleapis.com/css?family=Montserrat|Open+Sans'
    ],
    font_formats: 'Montserrat',
    branding: false,
    menubar: false,
    toolbar: 'undo redo | styleselect | forecolor | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | link image | code',
    inline: false,
    statusbar: false,
  
};

export const mapStylesLight = {
    style: [{
        featureType: 'landscape', stylers: [{ hue: '#FFBB00' },
        { saturation: 43.400000000000006 }, { lightness: 37.599999999999994 }, { gamma: 1 }]
    }, {
        featureType: 'road.highway', stylers: [{ hue: '#FFC200' },
        { saturation: -61.8 }, { lightness: 45.599999999999994 }, { gamma: 1 }]
    }, {
        featureType: 'road.arterial', stylers: [{ hue: '#FF0300' }, { saturation: -100 },
        { lightness: 51.19999999999999 }, { gamma: 1 }]
    }, {
        featureType: 'road.local', stylers: [{ hue: '#FF0300' }, { saturation: -100 },
        { lightness: 52 }, { gamma: 1 }]
    }, {
        featureType: 'water', stylers: [{ hue: '#0078FF' }, { saturation: -13.200000000000003 },
        { lightness: 2.4000000000000057 }, { gamma: 1 }]
    }, {
        featureType: 'poi', stylers: [{ hue: '#00FF6A' },
        { saturation: -1.0989010989011234 }, { lightness: 11.200000000000017 }, { gamma: 1 }]
    }]
};

export const mapStylesDark = {
    style: [{
        elementType: 'geometry', stylers: [{ hue: '#ff4400' }, { saturation: -100 }, { lightness: -4 }, { gamma: 0.72 }]
    }, {
        featureType: 'road',
        elementType: 'labels.icon'
    }, {
        featureType: 'landscape.man_made',
        elementType: 'geometry',
        stylers: [
            {
                hue: '#0077ff'
            },
            {
                gamma: 3.1
            }
        ]
    }, {
        featureType: 'water',
        stylers: [
            {
                hue: '#000000'
            },
            {
                gamma: 0.44
            },
            {
                saturation: -33
            }
        ]
    }, {
        featureType: 'poi.park',
        stylers: [
            {
                hue: '#44ff00'
            },
            {
                saturation: -23
            }
        ]
    }, {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
            {
                hue: '#007fff'
            },
            {
                gamma: 0.77
            },
            {
                saturation: 65
            },
            {
                lightness: 99
            }
        ]
    }, {
        featureType: 'water',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                gamma: 0.11
            },
            {
                weight: 5.6
            },
            {
                saturation: 99
            },
            {
                hue: '#0091ff'
            },
            {
                lightness: -86
            }
        ]
    }, {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
            {
                lightness: -48
            },
            {
                hue: '#ff5e00'
            },
            {
                gamma: 1.2
            },
            {
                saturation: -23
            }
        ]
    }, {
        featureType: 'transit',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                saturation: -64
            },
            {
                hue: '#ff9100'
            },
            {
                lightness: 16
            },
            {
                gamma: 0.47
            },
            {
                weight: 2.7
            }
        ]
    }, {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
            {
                color: '#222222'
            }
        ]
    },
    ]
};
